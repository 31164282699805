import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.location.href = "https://embed.yrmusix.com";
  }, []);

  return <div />;
}

export default App;
